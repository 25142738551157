import styled from "styled-components";


const Button = styled.button`
  border: none;
  padding: 9px;

  &:disabled {
    opacity: 50%;
  }
`;



export const ButtonSearchCepContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
`;

export const ButtonSearchCep = styled(Button)`
  background-color: #9802fe;

  margin-top: 1.875rem;

  border-top-right-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
`;