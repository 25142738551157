import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { formatCurrencyAsText } from '~/utils/functions';
import { usePedidoVenda } from '~/pages/PedidoVenda/PedidoVendaContext';

export const FormFinanceiro: React.FC = () => {
  const { parcelasRows } = usePedidoVenda();
  const [totalFinanceiro, setTotalFinanceiro] = useState(0);

  useEffect(() => {
    let totalParcelas = 0;
    parcelasRows.map((parcela) => {
      totalParcelas += parcela.val_parcela || 0;
      return parcela;
    });
    setTotalFinanceiro(totalParcelas);
  }, [parcelasRows, setTotalFinanceiro]);

  return (
    <>
      <Row className="d-flex align-items-end justify-content-between">
        <Col sm={12}>
          <h5
            style={{
              textAlign: 'right',
              display: 'block',
              marginTop: '20px',
            }}
          >
            {`Total Financeiro: R$${formatCurrencyAsText(
              totalFinanceiro || 0,
            )}`}
          </h5>
        </Col>
      </Row>
    </>
  );
};
