import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  height: 300px;
  margin-top: 16px;
`;


export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;
