import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  BuscaParceiro,
  BuscaProduto,
  InputAsyncSelect,
  InputDate,
  InputMoney,
  InputNumber2,
  InputSelect,
} from '~/components/NovosInputs';
import { usePedidoVenda } from '../../PedidoVendaContext';
import Separator from '~/components/Separator';
import { LojaContext } from '~/context/loja';
import { GreenItemContainer } from './styles';
import { FormItens } from './FormItens';
import { TableItens } from './TableItens';
import { toast } from 'react-toastify';
import {
  Message,
  ValidationRule,
  Validate,
  InternalFieldName,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { SelectType } from '~/components/NovosInputs/InputAsyncSelect/protocols';
import Loja from '~/components/Loja';

export const Pedido: React.FC = () => {
  const { codLoja, loja } = useContext(LojaContext);
  const {
    formPedidoVenda: {
      register,
      formState: { errors },
      control,
      setValue,
      getValues,
      clearErrors,
      watch,
    },
    lojaProduto,
    setLojaProduto,
    isUpdate,
    setIsUpdate,
    handleSearchEndereco,
  } = usePedidoVenda();
  const watch_loja = watch('cod_loja');

  const tipoNegociacao: Array<{
    value: number;
    label: string;
  }> = [
    {
      value: 0,
      label: 'Orçamento',
    },
    {
      value: 1,
      label: 'Pedido',
    },
    {
      value: 2,
      label: 'PDV',
    },
  ];
  const [tipoNegociacaoOptions, setTipoNegociacaoOptions] =
    useState(tipoNegociacao);

  useEffect(() => {
    let newTipoNegociacao = tipoNegociacao;
    if (!isUpdate) {
      newTipoNegociacao = tipoNegociacao.filter((item) => item.label !== 'PDV');
    }
    setTipoNegociacaoOptions(newTipoNegociacao);
  }, [isUpdate]);

  return (
    <>
      <Row>
        <Col md={6} sm={12} style={{ marginTop: 10 }}>
          <Loja
            selectedLoja={lojaProduto}
            IsInvalid={false}
            onChange={(e: number) => {
              setValue('cod_loja', e);
              setLojaProduto(e);
            }}
            isDisabled={false}
          />
        </Col>
        <Col md={6} sm={12}>
          <BuscaParceiro
            label="Cliente"
            placeholder="Selecione..."
            name="busca_parceiro"
            disabled={false}
            register={register}
            isError={!!errors.busca_parceiro}
            control={control}
            customOptions={{
              buscarPor: { cliente: true, fornecedor: false, loja: false },
            }}
            changeSelected={async (selected: any) => {
              clearErrors('busca_parceiro');
              const { des_uf }: any = selected;
              if (!des_uf) {
                toast.warning(
                  `Endereço incompleto, atualize o cadastro do parceiro para continuar.`,
                );
              } else {
                await handleSearchEndereco(selected.cod_pessoa);
              }

              const cod = selected.cod_pessoa ? selected.cod_pessoa : '';
              const nome = selected.nome_pessoa
                ? selected.nome_pessoa.trim()
                : '';
              const cidade = selected.des_cidade
                ? ` (${selected.des_cidade} - ${selected.des_uf})`
                : '';
              setValue('busca_parceiro', {
                ...selected,
                value: selected.value,
                label: `${cod} - ${nome} ${cidade}`,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <InputAsyncSelect
            label="Perfil"
            maxLength={50}
            listWidth="650px"
            placeholder="Selecione..."
            name="cod_perfil"
            register={register}
            isError={!!errors.cod_perfil}
            control={control}
            changeSelected={(selected: any) => {
              clearErrors('cod_perfil');
              setValue('cod_perfil', selected);
            }}
            title={
              !getValues('busca_parceiro')?.des_uf || !loja?.cod_loja
                ? 'É necessário selecionar parceiro e loja'
                : ''
            }
            disabled={false}
            api={{
              route: '/perfil-pedido-venda',
              method: 'get',
              fields: ['cod_perfil', 'des_perfil'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputAsyncSelect
            label="Vendedor"
            name="vendedor"
            placeholder="Selecione..."
            register={register}
            isError={!!errors.vendedor}
            maxLength={50}
            control={control}
            changeSelected={function (selected: SelectType) {
              clearErrors('vendedor');
              setValue('vendedor', selected);
            }}
            api={{
              route: '/vendedor-pedido-venda',
              bodyParams: {
                cod_loja: lojaProduto || codLoja,
              },
              method: 'get',
              fields: ['cod_usuario', 'des_usuario'],
              searchBeforeFilter: true,
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputSelect
            disabled={false}
            control={control}
            options={tipoNegociacaoOptions}
            register={register}
            setValue={setValue}
            placeholder="Selecione"
            label="Tipo"
            name="tipo_negociacao"
            changeSelected={(value) => setValue('tipo_negociacao', value)}
            isError={!!errors.tipo_negociacao}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputDate
            register={register}
            control={control}
            name="dta_emissao"
            label="Emissão"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const { value } = event?.target;
              clearErrors('dta_emissao');
              setValue('dta_emissao', value);
            }}
            disabled={false}
            isError={!!errors.dta_emissao}
          />
        </Col>
      </Row>
      <FormItens />
      <TableItens />
    </>
  );
};
