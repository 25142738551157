import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  height: 300px;
  margin-top: 16px;


  .sub-total {
    display: flex;
    align-items: flex-end;
    span {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .negative {
    span {
      color: red !important;
      text-decoration: underline !important;
    }
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;

export const SubTotal = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  gap: 10px;

  flex-wrap: nowrap;
  .sub-total {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    span {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .negative {
    span {
      color: red !important;
      text-decoration: underline !important;
    }
  }
`;