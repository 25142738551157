import styled from "styled-components";

export const Subtotal = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;


export const Container = styled.div`
  .sub-total {
    display: flex;
    align-items: flex-end;
    span {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .negative {
    span {
      color: red !important;
      text-decoration: underline !important;
    }
  }

  .add {
    button {
      background-color: #28a745;
      padding: 3px 12px;
      height: 40px;
      border-radius: 4px;
      border: none;
      color: #fff;
      animation: all 0.25s ease-in;

      &:active,
      &:focus {
        border: none;
        outline: none;
      }
      &:focus-within {
        outline: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const EntradaItensContainer = styled.div`
  /* padding: 20px; */
  padding-left: 12px;
  /* padding-bottom: 12px; */
  .bg-green {
    background-color: #b3f7b3;
    padding-bottom: 5px;
  }
  .bg-blue {
    background-color: #c4e9ed;
    padding-bottom: 5px;
  }

  .css-64pf72-Field {
    margin-top: 0px;
  }
`;

export const Button = styled.button`
  float: right;
  padding: 8px 8px 8px 8px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.4s;
  color: #424242;
  &:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  &:focus {
    outline: 0;
  }
  svg {
    color: #eda934 !important;
  }
`;
